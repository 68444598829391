/**
 * qd: 青岛出版社
 * sk: 上海科技教育出版社
 * zx: 中小学信息科技教材课程网
 * test-saas.zkxdr.net 是默认的
 */
export default {
  'qdpub.zkxdr.net': 'qd', // 生产
  'kexue.mmedu.net.cn': 'qd', // 生产
  'qdpub.zksr.net': 'qd', // 生产
  'skpub.zkxdr.net': 'zx', // 生产
  'www.xxkjjc.cn': 'zx', // 生产
  'shkjpub.zkxdr.net': 'sk', // 生产
  'shkjpub.zksr.net': 'sk', // 生产
  'testone-saas.zksr.net': 'qd',
  'test-saas.zksr.net': 'zx',
  'test-one.zkxdr.net': 'qd',
  '10.168.1.149:8080': 'qd'
}
