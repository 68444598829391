const config = {
  // 应用icon地址
  APP_ICON: '',
  // 未访问到icon时的提示文字
  APP_ICON_ALT: '',
  // 应用名称
  APP_NAME: '云科学教学端',
  // 应用名简称
  APP_SHORT_NAME: '云科学教学端',
  // 登录用户默认头像
  USER_AVATAR: '',
  fileDB: 'zksr',
  // 当前版本环境，在登录页显示
  env: 'Dev',
  // 登录用户默认昵称
  USERNAME: '',
  // 公共服务请求url
  // 测试环境：http://121.236.175.190，正式环境：http://117.80.186.186
  // 测试环境：1.4.0，正式环境：1.4.0
  // 打包需要修改更新接口，32位或64位
  coomUrl: {
    // pushUrl: 'https://test.zkxdr.net:8009'
    // pushUrl: 'http://www.zkxdr.net:8001'
    pushUrl: 'https://test-saas.zkxdr.net:8002'
  },
  path: {
    downloadDir: '',
    uploadDir: ''
  },
  miniapp: {
    envVersion: 'develop'
  },
  // 管理后台地址
  adminUrl: 'https://test-saas-edu-admin.zkxdr.net/',
  // 移动端地址
  mobileUrl: 'https://test-saas-edu-app.zkxdr.net/',
  // mqtt
  mqttUrl: 'wss://test-emqx.zkxdr.net:8084/mqtt',
  // 百度统计站点ID
  bdsiteId: 'fdf2d3695269c1e3b5b353030fe179f9',
  // 基础请求url
  baseUrl: {
    // dev: 'https://www.zkxdr.net/api/',
    // dev: 'https://pre.zkxdr.net/api/', // 预发
    dev: 'https://test-saas.zkxdr.net/api/',
    // dev: '/api/',
    // dev: 'http://127.0.0.1:8080/', // 在线
    pro: '/api/' // 测试服务器
    // pro: 'http://192.168.1.12:8080/' // 彭总本地,
    // pro: 'http://10.168.1.8:8080/', // 周逸峰本地
  }
}

if (process.env.URL_ENV === 'pre') {
  config.baseUrl.dev = 'https://pre.zkxdr.net/api/'
  config.baseUrl.dev = 'https://pre.zkxdr.net/api/'
  // 预发布服务器
  config.baseUrl.pro = '/api/'
  config.coomUrl.pushUrl = location.host === 'www.zkxdr.net' ? 'https://www.zkxdr.net:8001' : 'https://pre.zkxdr.net:8002'
  config.adminUrl = location.host === 'www.zkxdr.net' ? 'https://admin.zkxdr.net/' : 'https://preadmin.zkxdr.net/'
  config.mobileUrl = location.host === 'www.zkxdr.net' ? 'https://mast.zkxdr.net/' : 'https://premast.zkxdr.net/'
  config.mqttUrl = 'wss://pre-emqx.zkxdr.net:8085/mqtt'
  config.miniapp = {
    envVersion: 'trial'
  }
}
if (process.env.URL_ENV === 'prod') {
  config.baseUrl.dev = location.host === 'www.zkxdr.net' ? 'https://www.zkxdr.net/api/' : 'https://www.zkxdr.net/api/'
  config.baseUrl.dev = location.host === 'www.zkxdr.net' ? 'https://www.zkxdr.net/api/' : 'https://www.zkxdr.net/api/'
  // 预发布服务器
  config.baseUrl.pro = '/api/'
  config.coomUrl.pushUrl = location.host === 'www.zkxdr.net' ? 'https://www.zkxdr.net:8001' : 'https://www.zkxdr.net:8001'
  config.adminUrl = location.host === 'www.zkxdr.net' ? 'https://admin.zkxdr.net/' : 'https://admin.zkxdr.net/'
  config.mobileUrl = location.host === 'www.zkxdr.net' ? 'https://mast.zkxdr.net/' : 'https://mast.zkxdr.net/'
  config.mqttUrl = 'wss://prod-emqx.zkxdr.net:8086/mqtt'
  config.miniapp = {
    envVersion: 'release'
  }
}

export default config
