import * as pageInfo from '@/config/page.js'
import pageConfig from '@/config/url.js'
import { getCurrentPressInfoApi } from '@/api/press/index.js'

const href = location.host

// const pageConfig = {
//   'qdpub.zkxdr.net': 'qd',
//   'kexue.mmedu.net.cn': 'qd',
//   'qdpub.zksr.net': 'qd',
//   'shkjpub.zkxdr.net': 'sk',
//   'shkjpub.zksr.net': 'sk',
//   'testone-saas.zksr.net': 'qd',
//   'test-saas.zksr.net': 'sk',
//   'localhost:8080': 'sk'
// }
// 用户信息
const appInfo = {
  namespaced: true,
  state: {
    name: 'zksr',
    config: pageInfo[pageConfig[href] || 'normal'],
    isTestEnv: href.search('test') >= 0 || href.search('localhost') >= 0
  },
  mutations: {
    setPressInfo (state, config) {
      state.config = config
    }
  },
  actions: {
    // 获取用户信息
    getPressInfo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (href.search('www') === 0) {
          resolve(state.config)
        } else {
          return getCurrentPressInfoApi().then(res => {
            if (res.status === 200 && res.data && res.data.data) {
              const data = res.data.data
              const config = Object.assign({}, state.config)
              config.title = data.name
              config.logoText = data.logoPathUrl || config.logoText
              config.logoTextWhite = data.logo2PathUrl || config.logoText
              config.logo = data.logo3PathUrl || config.logo
              config.testId = data.id
              config.publishId = data.id
              config.banner = data.bannerPathUrl || config.banner
              config.copyRight = data.copyRight
              config.platformAlias = data.platformAlias
              resolve(config)
              commit('setPressInfo', config)
            }
          })
        }
      })
    }
  }
}

export default appInfo
