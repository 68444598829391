/* eslint-disable vue/multi-word-component-names */

import Vue from 'vue'
// 按需引入iview组件（根据设计重写样式，后期会更新为base-components）
import {
  Row,
  Col,
  Form,
  Button, ButtonGroup,
  Input,
  Select,
  Option,
  Table,
  Page,
  Rate,
  Poptip,
  Spin,
  Menu,
  MenuGroup,
  MenuItem,
  Submenu,
  Tabs,
  Tag,
  TabPane,
  Tree,
  Modal,
  Badge,
  Message,
  Icon,
  Carousel,
  CarouselItem,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Upload,
  FormItem,
  Progress,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Circle,
  Cascader,
  Tooltip,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  TimePicker,
  Layout,
  Sider,
  Content,
  Header,
  Collapse,
  Panel,
  Drawer,
  Card,
  Slider,
  InputNumber
} from 'view-design'
import 'view-design/dist/styles/iview.css'

Vue.component('Layout', Layout)
Vue.component('Sider', Sider)
Vue.component('Card', Card)
Vue.component('Content', Content)
Vue.component('MainHeader', Header)
Vue.component('ButtonGroup', ButtonGroup)
Vue.component('Row', Row)
Vue.component('Slider', Slider)
Vue.component('Col', Col)
Vue.component('Form', Form)
Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('InputNumber', InputNumber)
Vue.component('Select', Select)
Vue.component('Option', Option)
Vue.component('Table', Table)
Vue.component('Page', Page)
Vue.component('Rate', Rate)
Vue.component('Poptip', Poptip)
Vue.component('Spin', Spin)
Vue.component('Menu', Menu)
Vue.component('MenuGroup', MenuGroup)
Vue.component('MenuItem', MenuItem)
Vue.component('Submenu', Submenu)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('BreadcrumbItem', BreadcrumbItem)
Vue.component('Tabs', Tabs)
Vue.component('Tag', Tag)
Vue.component('TabPane', TabPane)
Vue.component('Tree', Tree)
Vue.component('Modal', Modal)
Vue.component('Badge', Badge)
Vue.component('Message', Message)
Vue.component('Icon', Icon)
Vue.component('Tooltip', Tooltip)
Vue.component('Carousel', Carousel)
Vue.component('CarouselItem', CarouselItem)
Vue.component('DatePicker', DatePicker)
Vue.component('TimePicker', TimePicker)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('DropdownItem', DropdownItem)
Vue.component('Upload', Upload)
Vue.component('FormItem', FormItem)
Vue.component('Progress', Progress)
Vue.component('CheckboxGroup', CheckboxGroup)
Vue.component('Checkbox', Checkbox)
Vue.component('RadioGroup', RadioGroup)
Vue.component('Radio', Radio)
Vue.component('Divider', Divider)
Vue.component('iCircle', Circle)
Vue.component('Cascader', Cascader)
Vue.component('Collapse', Collapse)
Vue.component('Panel', Panel)
Vue.component('Drawer', Drawer)
Vue.prototype.$Spin = Spin
Vue.prototype.$Modal = Modal
Vue.prototype.$Message = Message
Vue.prototype.$Message.config({
  top: 200,
  duration: 4
})
