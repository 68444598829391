import logo from '@/assets/icon/logo.png'
import logoQd from '@/assets/icon/logo-qd.png'
import logoSk from '@/assets/icon/logo-sk.png'
import logoText from '@/assets/login/logo.png'
import logoTextQd from '@/assets/login/logo-qd.png'
import logoTextSk from '@/assets/login/logo-sk.png'
import logoTextZx from '@/assets/login/logo-zx.png'
import logoTextZxBlack from '@/assets/login/logo-zx-black.png'
import banner from '@/assets/website/banner.png'
import bannerQD from '@/assets/website/banner-qd.png'
import bannerSK from '@/assets/website/banner-sk.jpg'
import bannerZX from '@/assets/website/banner-zx.jpg'

export const normal = {
  type: 'normal',
  pageType: 'noraml',
  name: '云科学综合素养教育平台',
  title: '云科学',
  logoText,
  logoTextWhite: logoText,
  logo,
  banner,
  testId: '',
  publishId: '',
  authType: 1,
  menus: {
    home: {
      show: 0,
      name: '首页',
      componentName: 'Home'
    },
    base_course: {
      show: 0,
      name: '基础课',
      componentName: 'science'
    },
    schl_course: {
      show: 0,
      name: '校本教材',
      componentName: 'schoolRes'
    },
    res_repo: {
      show: 0,
      name: '资源库',
      componentName: 'search'
    },
    edu_experiment: {
      show: 0,
      name: '实验专区',
      componentName: 'ClassExpriment',
      childs: []
    },
    edu_training: {
      show: 0,
      name: '教学成长',
      componentName: 'teacherCourse',
      childs: []
    },
    club_course: {
      show: 0,
      name: '课后服务',
      componentName: 'societies',
      childs: []
    },
    edu_live: {
      show: 0,
      name: '直播天地',
      componentName: 'live',
      childs: []
    },
    edu_AI: {
      show: 0,
      name: 'AI助手',
      componentName: 'AI',
      childs: []
    },
    edu_lab: {
      show: 0,
      name: '亲子任务',
      componentName: 'lab',
      childs: []
    },
    edu_mall: {
      show: 0,
      name: '积分商城',
      componentName: 'mall'
    },
    edu_expert: {
      show: 0,
      name: '科教协同',
      componentName: 'expert',
      childs: []
    },
    ucenter: {
      show: 0,
      name: '个人中心',
      componentName: 'ucenter',
      childs: []
    },
    edu_paper: {
      show: 0,
      name: '试题/试卷',
      componentName: 'iacPaper',
      childs: []
    }
  },
  ucenterMenus: [
    {
      id: 3000,
      label: '账号信息',
      path: '/ucenter/account/profile',
      src: '~@/assets/icon-account.png',
      children: [
        { id: 3001, label: '个人信息', path: '/ucenter/account/profile', isAuth: false },
        // { id: 3007, label: '个人空间', path: '/ucenter/account/space', isAuth: false },
        { id: 3002, label: '我的上传', path: '/ucenter/resource/resource', isAuth: false },
        { id: 3003, label: '我的发布', path: '/ucenter/publish/publish', isAuth: false },
        { id: 3004, label: '我的积分', path: '/ucenter/point/point', isAuth: false },
        // { id: 3005, label: '账号安全', path: '/ucenter/account/security', isAuth: false },
        { id: 3006, label: '关于我们', path: '/ucenter/aboutus', isAuth: false },
        { id: 3008, label: '我的消息', path: '/ucenter/mymessage/list', isAuth: false },
        { id: 3009, label: '我的订单', path: '/ucenter/order/list', isAuth: false }
      ]
    },
    {
      id: 1000,
      label: '教务管理',
      src: '~@/assets/icon-class.png',
      children: [
        { id: 1001, label: '班级管理', path: '/ucenter/class/list', isAuth: false },
        { id: 1002, label: '课表管理', path: '/ucenter/class/timetable', isAuth: false },
        { id: 1003, label: '学生信息', path: '/ucenter/student/list', isAuth: false }
      ]
    }
  ]
}
// 青岛
export const qd = {
  type: 'qdPub',
  pageType: 'custom',
  name: '青版科学智慧教育云平台',
  title: '青版科学智慧教育云平台',
  logoText: logoTextQd,
  logoTextWhite: logoTextQd,
  logo: logoQd,
  banner: bannerQD,
  testId: 'bebf2bee746cf7410199d9fc1821e1d9',
  publishId: '28c8e376828549fcb27fdf5aaef1ab1d',
  authType: 2,
  ver: '10',
  subject: '1',
  section: '1',
  menus: {
    home: {
      show: 0,
      name: '首页',
      componentName: 'Home'
    },
    base_course: {
      show: 0,
      name: '基础课',
      componentName: 'science'
    },
    res_repo: {
      show: 0,
      name: '资源库',
      componentName: 'search'
    },
    edu_training: {
      show: 0,
      name: '教学成长',
      componentName: 'teacherCourse',
      childs: []
    },
    edu_live: {
      show: 0,
      name: '直播天地',
      componentName: 'live',
      childs: []
    },
    edu_experiment: {
      show: 0,
      name: '实验专区',
      componentName: 'ClassExpriment',
      childs: []
    },
    edu_AI: {
      show: 0,
      name: 'AI+',
      componentName: 'AI',
      childs: []
    },
    ucenter: {
      show: 0,
      name: '个人中心',
      componentName: 'ucenter',
      childs: []
    },
    edu_paper: {
      show: 0,
      name: '试题/试卷',
      componentName: 'iacPaper',
      childs: []
    }
  },
  ucenterMenus: [
    {
      id: 3000,
      label: '账号信息',
      path: '/ucenter/account/profile',
      src: '~@/assets/icon-account.png',
      children: [
        { id: 3001, label: '个人信息', path: '/ucenter/account/profile', isAuth: false },
        // { id: 3007, label: '个人空间', path: '/ucenter/account/space', isAuth: false },
        { id: 3002, label: '我的上传', path: '/ucenter/resource/resource', isAuth: false },
        { id: 3003, label: '我的发布', path: '/ucenter/publish/publish', isAuth: false },
        { id: 3004, label: '我的积分', path: '/ucenter/point/point', isAuth: false }
        // { id: 3005, label: '账号安全', path: '/ucenter/account/security', isAuth: false },
        // { id: 3006, label: '关于我们', path: '/ucenter/aboutus', isAuth: false }
      ]
    },
    {
      id: 1000,
      label: '教务管理',
      src: '~@/assets/icon-class.png',
      children: []
    }
  ]
}
// 上海科技教育出版社
export const sk = {
  type: 'skPub',
  pageType: 'custom',
  name: '上海科教智慧教育云平台',
  title: '上海科教智慧教育云平台',
  logoText: logoTextSk,
  logoTextWhite: logoTextSk,
  logo: logoSk,
  banner: bannerSK,
  testId: '10255c25fc1f479fa98d322a55cae077',
  publishId: '93c4e3d46d3846e7bc9f80c8a074adcc',
  authType: 2,
  ver: '3',
  subject: '6',
  section: '2',
  menus: {
    home: {
      show: 0,
      name: '首页',
      componentName: 'Home'
    },
    base_course: {
      show: 0,
      name: '基础课',
      componentName: 'science'
    },
    res_repo: {
      show: 0,
      name: '资源库',
      componentName: 'search'
    },
    edu_training: {
      show: 0,
      name: '教学成长',
      componentName: 'teacherCourse',
      childs: []
    },
    edu_live: {
      show: 0,
      name: '直播天地',
      componentName: 'live',
      childs: []
    },
    edu_experiment: {
      show: 0,
      name: '实验专区',
      componentName: 'ClassExpriment',
      childs: []
    },
    // edu_AI: {
    //   show: 0,
    //   name: 'AI+',
    //   componentName: 'AI',
    //   childs: []
    // },
    ucenter: {
      show: 0,
      name: '个人中心',
      componentName: 'ucenter',
      childs: []
    },
    edu_paper: {
      show: 0,
      name: '试题/试卷',
      componentName: 'iacPaper',
      childs: []
    }
  },
  ucenterMenus: [
    {
      id: 3000,
      label: '账号信息',
      path: '/ucenter/account/profile',
      src: '~@/assets/icon-account.png',
      children: [
        { id: 3001, label: '个人信息', path: '/ucenter/account/profile', isAuth: false },
        // { id: 3007, label: '个人空间', path: '/ucenter/account/space', isAuth: false },
        { id: 3002, label: '我的上传', path: '/ucenter/resource/resource', isAuth: false },
        { id: 3003, label: '我的发布', path: '/ucenter/publish/publish', isAuth: false },
        { id: 3004, label: '我的积分', path: '/ucenter/point/point', isAuth: false }
        // { id: 3005, label: '账号安全', path: '/ucenter/account/security', isAuth: false },
        // { id: 3006, label: '关于我们', path: '/ucenter/aboutus', isAuth: false }
      ]
    },
    {
      id: 1000,
      label: '教务管理',
      src: '~@/assets/icon-class.png',
      children: []
    }
  ]
}

// 中小学信息科技教材课程网
export const zx = {
  type: 'zxPub',
  pageType: 'custom',
  name: '中小学信息科技教材课程网',
  title: '中小学信息科技教材课程网',
  logoText: logoTextZxBlack,
  logoTextWhite: logoTextZx,
  logo: logoSk,
  banner: bannerZX,
  testId: '84ee7c818b4c47c0afc27d480cb4774a',
  publishId: 'd9d02a0002fe4619b465c83cde261825',
  authType: 2,
  ver: '2',
  subject: '2',
  section: '1',
  menus: {
    base_course: {
      show: 0,
      name: '基础课',
      componentName: 'science',
      moduleName: '电子教材'
    },
    res_repo: {
      show: 0,
      name: '资源库',
      moduleName: '教学资源',
      componentName: 'search'
    },
    ucenter: {
      show: 0,
      name: '个人中心',
      componentName: 'ucenter',
      moduleName: '个人中心',
      childs: []
    }
  },
  ucenterMenus: [
    {
      id: 3000,
      label: '账号信息',
      path: '/ucenter/account/profile',
      src: '~@/assets/icon-account.png',
      children: [
        { id: 3001, label: '个人信息', path: '/ucenter/account/profile', isAuth: false },
        // { id: 3007, label: '个人空间', path: '/ucenter/account/space', isAuth: false },
        { id: 3002, label: '我的上传', path: '/ucenter/resource/resource', isAuth: false },
        { id: 3003, label: '我的发布', path: '/ucenter/publish/publish', isAuth: false },
        { id: 3004, label: '我的积分', path: '/ucenter/point/point', isAuth: false }
        // { id: 3005, label: '账号安全', path: '/ucenter/account/security', isAuth: false },
        // { id: 3006, label: '关于我们', path: '/ucenter/aboutus', isAuth: false }
      ]
    },
    {
      id: 1000,
      label: '教务管理',
      src: '~@/assets/icon-class.png',
      children: []
    }
  ],
  topConfig: ['top-1']
}
